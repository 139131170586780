<template>
	<!-- NOTE: Webview以外なら表示 -->
	<aside v-if="!isWebview" class="sugotoku-button">
		<!-- NOTE: Androidは「アプリDLボタン」を表示 -->
		<div v-if="isAndroid" class="sugotoku-button__app-download">
			<div class="app-download__img">
				<img src="@/assets/img/sugotou_gadget@2x.png" alt="あなたの頭痛を気圧で予報！ 頭痛ーるforスゴ得 あなたの頭痛、「気圧」が原因かもしれません" >
			</div>
			<div class="app-download__button--wrapper about-rightnow__login">
				<a href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="app-download__button about-rightnow__login--button">今すぐアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
		</div>
		<div v-else-if="isIosAppDownload" class="sugotoku-button__app-download">
			<div class="app-download__button--wrapper about-rightnow__login">
				<span class="app-download__button about-rightnow__login--button is-ios-app-download" @click="routerLink('appDownload')"><span>＼プッシュ機能で低気圧をお知らせ！／</span>とっても便利なアプリはこちら！</span>
			</div>
		</div>
		<!-- NOTE: iOSはマイページ未登録であれば「マイページ登録ボタン」を表示 -->
		<div v-else-if="!isMyPage" class="sugotoku-button__mypage">
			<div class="balloon__wrapper">
				<div class="balloon">
					<div class="balloon__inner">
						<div class="balloon__text--wrapper">
							<p class="balloon__text--title">とっても便利！</p>
							<p class="balloon__text">マイページ登録をすると、気圧予報や新着情報が毎日簡単にチェックできるよ&#9834;</p>
						</div>
						<div class="balloon__img--wrapper">
							<i class="sprite__mypage-button mypage-img" />
						</div>
					</div>
					<div class="balloon__close" @click="SET_IS_MY_PAGE(true)">
						<i class="sprite__mypage-button close-icon" />
					</div>
				</div>
			</div>
			<div :class="[
				'dcm_favorite_separateButton',
				'dcm_typeRed',
				'mypage__button',
				isLocalClass,
			]">
				<p>
					<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
				</p>
			</div>
		</div>
	</aside>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_IS_MY_PAGE } from '../../store/modules/common/mutation-types'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SugotokuButton',
	data () {
		return {
			isLocalClass: ''
		}
	},
	props: {
		isIosAppDownload: {
			type: Boolean,
			default: false
		},
		isBackPageTop: {
			type: Boolean,
			default: false
		}
	},
	mounted () {
		this.isLocalClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isMyPage', 'isAndroid', 'isWebview'])
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_IS_MY_PAGE]),

		/**
		 * マイページ登録ボタンクリック処理
		 */
		clickMypageButton (e) {
			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// NOTE: 新宿の母を見るとマイページ登録後にボタンを非表示にしていないので、非表示にしない。もし非表示にする場合、コンテンツの高さを再取得してthis.SET_IS_MY_PAGE(true)をする必要がある。
			// this.SET_IS_MY_PAGE(true)

			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		},

		/**
		 * ルーティング用リンク
		 */
		routerLink (path) {
			const query = { url: `${cmnConst.BACKEND_URL}?_path=${path}` }
			if (this.isBackPageTop) query['_backPage'] = 'top'
			this.$router.push({ name: 'Cpsite', query: query })
		}
	},
	beforeDestroy () {
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

// NOTE: スゴ得マイページ・アプリダウンロード限定のカラー（デザインシステム外のため他での利用禁止）
$sugotoku-mypage-red: #d2193f;
$sugotoku-mypage-background: #fffee2;
$sugotoku-app-download-red: #cb0034;

.sugotoku-button { font-family: $family-primary; }

.is-ios-app-download {
	background-color: $sugotoku-app-download-red;
}

.balloon {
	position: relative;
	width: 87.5%;
	margin: 0 auto $spacing-10;
	padding: $spacing-10 $spacing-16;
	box-sizing: border-box;
	border: 2px solid $sugotoku-mypage-red;
	border-radius: 5px;
	background-color: $sugotoku-mypage-background;
	font-size: $font-size-14;
	font-weight: bold;
	color: $text-primary;
	line-height: $line-height;
	text-align: left;
	// animation
	animation: floating 2.2s ease-in-out 0s infinite normal;
	transform: translate(0px, 5px);
	z-index: 50;

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-width: 13px 6px;
		border-style: solid;
		border-color: transparent;
		left: 50%;
	}

	&::before {
		border-top-color: $sugotoku-mypage-red;
		bottom: -26px;
	}

	&::after {
		border-top-color: $sugotoku-mypage-background;
		bottom: -22px;
	}

	&__wrapper { padding: $spacing-10 0; }

	&__inner { display: table; }

	&__text {
		margin: $spacing-2 0 $spacing-10;

		&--wrapper {
			display: table-cell;
			vertical-align: middle;
			padding-right: $spacing-10;
		}

		&--title {
			display: block;
			margin: $spacing-10 0 $spacing-2;
			padding: $spacing-2 0;
			background: $sugotoku-mypage-red;
			text-align: center;
			font-size: $font-size-16;
			color: $_white;
			line-height: $line-height-1;
		}
	}

	&__img--wrapper {
		display: table-cell;
		vertical-align: middle;
		width: 89px;
	}

	&__close {
		position: absolute;
		display: block;
		box-sizing: border-box;
		top: -11px;
		right: -11px;
	}
}

.app-download__img {
	width: 100%;
	margin: 0 auto;

	img {
		max-width: 100%;
		vertical-align: top;
	}
}

@-webkit-keyframes floating {
	0%   { transform: translate(0px, 5px); }
	50%  { transform: translate(0px, 0px); }
	100% { transform: translate(0px, 5px); }
}
@keyframes floating {
	0%   { transform: translate(0px, 5px); }
	50%  { transform: translate(0px, 0px); }
	100% { transform: translate(0px, 5px); }
}

.mypage__button p {
	width: 83.4375%;
	margin: 0 auto;
}

// NOTE: スゴ得マイページ登録ボタンのローカル確認用（デザインシステム外のため流用禁止）
.is-local {
	&.mypage__button {
		box-sizing: border-box;

		p { text-align: center; }

		a {
			display: block;
			padding: 14px 10px;
			font-size: 12px;
			text-decoration: none;
			color: #000000;
			border-radius: 10px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
		}

		&.dcm_typeRed a {
			color: #fff;
			background: linear-gradient(to bottom, #fe5c85 0%, #fd2e62 5%, #a00117 95%, #5c000b 100%);
		}
	}
}

// sprite
.sprite__mypage-button {
	background-image: url(~@/assets/img/sprite_mypage_button.png);
	background-repeat: no-repeat;
	display: block;
	background-size: (178px / 2) (227px / 2);

	&.close-icon {
		width: (50px / 2);
		height: (50px / 2);
		background-position: 0 0;
	}

	&.mypage-img {
		width: (178px / 2);
		height: (177px / 2);
		background-position: 0 (-50px / 2);
	}
}

</style>
