<template>
<section class="column-detail" id="js_page_top">
	<common-header
		type="allow"
		@click="$router.back()"
		v-if="!isWebview"
	>
		<h1 class="common-header-title__heading">頭痛ーるコラム</h1>
	</common-header>

	<!-- ローディング -->
	<transition name="fade">
		<redraw-progress v-if="isLoading" :is-fixed="true" />
	</transition>
	<!-- /ローディング -->

	<article class="column-detail__container" id="js_column-datail_container">
		<!-- ページ上部のカテゴリアコーディオン -->
		<column-category-selector :columnCategoryList="columnCategoryList" @categoryId="selectCategoryId($event)" />

		<!-- 記事 -->
		<header class="column-detail__header-title" id="js_observer">
			<h2 class="column-detail__header-title--inner">{{ columnDetailItem.categoryName }}</h2>
		</header>

		<h3 class="column-detail__title" v-html="columnDetailItem.detailTitle"></h3>
		<section class="column-detail__article" v-if="columnDetailItem" v-html="columnDetailItem.detailContent"></section>

		<!-- アプリダウンロード・マイページ登録ボタン -->
		<sugotoku-button class="column-detail__sugotoku-button" />

		<!-- 回遊記事 -->
		<section class="category-posts">
			<div class="category-posts__title">{{ columnDetailItem.categoryName }}の記事一覧</div>
			<ul class="category-posts__list">
				<li
					v-for="columnItem in columnList"
					:key="`columnItem-${columnItem.id}`"
					:class="[
						{ 'is-active': columnItem.isActive },
						'category-posts__list--item'
					]"
					@click="selectDetailId(columnItem.id)"
					@touchstart="columnItem.isActive = true"
					@touchend="columnItem.isActive = false"
				>
					<div class="category-posts__list--img">
						<figure class="category-posts__list--img-inner">
							<img v-if="columnItem.sourceUrl" :src="`${columnItem.sourceUrl}`" :alt="`${columnItem.altText}`" />
							<img v-else src="@/assets/img/column_noimage.jpg" />
						</figure>
					</div>
					<div class="category-posts__list--textarea">
						<span class="category-posts__list--category" :class="`${columnItem.slug}`">{{ columnItem.category }}</span>
						<p class="category-posts__list--title">{{ columnItem.title }}</p>
					</div>
				</li>
			</ul>
			<div class="category-posts__more-button" v-show="columnList">
				<p @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnTop`, _categoryId: columnDetailItem.categoryId } })">もっと見る</p>
			</div>
		</section>

		<footer class="column-detail__footer">
			<!-- ページ下部のカテゴリ一覧 -->
			<column-lower-category-list :columnCategoryList="columnCategoryList" @categoryId="selectCategoryId($event)" />

			<!-- コラムTOPへのリンク -->
			<div class="column-detail__back-button--wrapper">
				<p class="column-detail__back-button is-column-top" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnTop` } })">頭痛ーるコラムTOPへ</p>
			</div>

			<!-- 特集にもどる -->
			<a v-if="!isWebview && previousPage === 'rainyseason2023'" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'rainyseason2023' } })" class="column-detail__lptop">梅雨特集TOPへもどる</a>
			<!-- /特集にもどる -->

			<!-- Android TOPへのリンク -->
			<div class="column-detail__back-button--wrapper" v-if="isAndroid && !isWebview">
				<p class="column-detail__back-button is-top" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload` } })">TOPにもどる</p>
			</div>
			<!-- iOS TOPへのリンク -->
			<div class="column-detail__back-button--wrapper" v-else-if="!isAndroid">
				<p class="column-detail__back-button is-top" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=weatherGraph` } })">気圧グラフにもどる</p>
			</div>
		</footer>

		<!-- 上部に戻るボタン -->
		<nav class="column-detail__pagetop" id="js_pagetop_btn">
			<a href="javascript:void(0);" @click.prevent="onScroll('js_page_top')" class="column-detail__pagetop--link">
				<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
			</a>
		</nav>
		<!-- /上部に戻るボタン -->
	</article>
</section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_COLUMN_DETAIL, SET_COLUMN_CATEGORY, SET_COLUMN_LIST } from '../store/modules/column/mutation-types'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'
import RedrawProgress from '../components/Molecules/RedrawProgress'
import ColumnCategorySelector from '../components/Organisms/ColumnCategorySelector'
import ColumnLowerCategoryList from '../components/Organisms/ColumnLowerCategoryList'
import SugotokuButton from '../components/Organisms/SugotokuButton'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'ColumnDetail',
	props: {
		detailId: {
			type: Number,
			default: null
		}
	},
	components: {
		CommonHeader,
		RedrawProgress,
		ColumnCategorySelector,
		ColumnLowerCategoryList,
		SugotokuButton
	},
	data () {
		return {
			columnDetailItem: {
				detailId: null,
				detailTitle: null,
				detailContent: null,
				categoryId: null,
				categoryName: null
			},
			columnCategoryList: null,
			columnList: null,
			backendUrl: cmnConst.BACKEND_URL,
			isLoading: false,
			pageTopObserver: null,
			previousPage: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isWebview', 'isAndroid']),
		...mapGetters('column', ['columnDetailData', 'columnCategoryData', 'columnListData'])
	},
	mounted () {
		// webview時の処理
		if (this.isWebview) this.backendUrl = 'webview'

		console.log('detailId: ', this.detailId)
		this.setColumnDetail(this.detailId)

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)

		// $route.queryの_previousPageがあれば特集ページにもどるボタンを出しわけする
		if (this.$route.query._previousPage) this.previousPage = this.$route.query._previousPage
	},
	beforeDestroy () {
		// スゴ得の場合、コンテンツの高さをautoでemitする。
		// CHANGE: v-ons-page廃止によりコメントアウト
		// if (!this.isWebview) this.$emit('containerHeight', 'auto')

		// IntersectionObserverの監視を停止
		this.pageTopObserver.disconnect()
	},
	methods: {
		// map Vuex actions
		...mapActions('column', [SET_COLUMN_DETAIL, SET_COLUMN_CATEGORY, SET_COLUMN_LIST]),

		/**
		 * 記事取得処理
		 */
		setColumnDetail (id) {
			// detailIdがnullならNotfound
			if (!id) this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=notFound`, _detailId: 'null' } })

			// APIをローカル変数に
			const SET_COLUMN_DETAIL = this.SET_COLUMN_DETAIL({
				'detail_id': id
			})
			const SET_COLUMN_CATEGORY = this.SET_COLUMN_CATEGORY()

			// NOTE: カテゴリデータがなければカテゴリデータと記事データ取得。あれば記事データのみ取得
			let apiArray = null
			if (this.columnCategoryData) {
				apiArray = [ SET_COLUMN_DETAIL ]
			} else {	// 通常の処理
				apiArray = [ SET_COLUMN_DETAIL, SET_COLUMN_CATEGORY ]
			}
			// console.log('apiArray: ', apiArray)

			this.isLoading = true
			Promise.all(apiArray).then(res => {
				// SET_COLUMN_DETAIL, SET_COLUMN_CATEGORY成功処理
				// console.log('columnDetailData', this.columnDetailData)
				// console.log('columnCategoryData', this.columnCategoryData)
				this.columnCategoryList = this.columnCategoryData ? this.columnCategoryData.map(item => {
					return {
						'id': item.id,
						'name': item.name,
						'count': item.count
					}
				}).filter(item => {
					return item.count !== 0
				}) : null
				if (this.columnDetailData.message) {
					// 記事がない場合のエラー処理
					console.log('error: ', this.columnDetailData.message)
					this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=notFound`, _detailId: id } })
				} else if (this.columnDetailData.status !== 'publish') {
					// publish: 公開以外(future: 公開予約, draft: 下書き, pending: 非公開, private: 限定公開)
					// NOTE: 公開以外のケースはエラーレスポンスがくるのでこの分岐に入ることはなさそう。いったんNotFoundにしておく。
					console.log('status error: ', this.columnDetailData.status)
					this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=notFound`, _detailId: id } })
				} else {
					this.columnDetailItem.detailId = this.columnDetailData.id
					this.columnDetailItem.detailContent = this.columnDetailData.content.rendered
					this.columnDetailItem.detailTitle = this.columnDetailData.title.rendered
					this.columnDetailItem.categoryId = this.columnDetailData.categories[0] ? this.columnDetailData.categories[0] : null
					const categoryItem = this.columnCategoryList ? this.columnCategoryList.find(item => item.id === this.columnDetailItem.categoryId) : null
					this.columnDetailItem.categoryName = categoryItem ? categoryItem.name : null
					// カテゴリ関連記事取得
					return this.SET_COLUMN_LIST({
						per_page: 4,
						page: 1,
						categories: this.columnDetailItem.categoryId ? this.columnDetailItem.categoryId : ''
					})
				}
				// console.log('columnDetailItem', this.columnDetailItem)
			}).then(res => {
				// SET_COLUMN_LIST成功処理
				const arr = this.columnListData ? this.columnListData.filter(item => item.id !== this.columnDetailItem.detailId) : []	// 表示している記事は取り除く
				if (arr.length === 4) arr.pop()	// リストに表示している記事がない場合は末尾の記事を取り除く
				this.columnList = arr.map(item => {
					return {
						'id': item.id,
						'title': item.title.rendered,
						'category': item['_embedded']['wp:term'] ? item['_embedded']['wp:term'][0][0]['name'] : null,
						'slug': item['_embedded']['wp:term'] ? item['_embedded']['wp:term'][0][0]['slug'] : null,
						'sourceUrl': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['source_url'] : null,
						'altText': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['alt_text'] : null,
						'isActive': false
					}
				})
				// console.log('columnListData: ', this.columnListData)
				// console.log('columnList: ', this.columnList)
				// コンテンツの高さをemitする処理
				// CHANGE: v-ons-page廃止によりコメントアウト
				// this.$nextTick(() => {
				// 	const domContainer = document.getElementById('js_column-datail_container')
				// 	// 表示が崩れるため、一旦「setTimeout」で対応
				// 	setTimeout(() => {
				// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				// 	}, 1000)
				// })

				// 記事内のアプリダウンロードボタン出しわけ
				if (!this.isWebview) {
					this.$nextTick(() => {
						this.handleDetailContentAppDownloadButton()
					})
				}
			}).catch(error => {
				// エラー処理
				console.log(error)
				this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=error`, type: 'columnDetail' } })
			}).finally(() => {
				this.isLoading = false
			})
		},

		/**
		 * カテゴリを選択したときの処理
		 */
		selectCategoryId (e) {
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnTop`, _categoryId: e } })
		},

		/**
		 * コラム記事を選択したときの処理
		 */
		selectDetailId (e) {
			this.columnDetailItem.detailId = null
			this.columnDetailItem.detailTitle = null
			this.columnDetailItem.detailContent = null
			this.columnDetailItem.categoryId = null
			this.columnDetailItem.categoryName = null
			this.columnList = null
			this.setColumnDetail(e)
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnDetail`, _detailId: e } })
		},

		/**
		 * コラム記事内のアプリダウンロードボタン出しわけ処理
		 */
		handleDetailContentAppDownloadButton () {
			const className = this.isAndroid ? '.js_sugotoku_app_download_android' : '.js_sugotoku_app_download_ios'
			const targets = document.querySelectorAll(className)

			if (targets.length > 0) targets.forEach($ele => $ele.setAttribute('style', 'display: block;'))
		},

		/**
		 * ページスクロール用の関数
		 */
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";
@import "../assets/sass/column_detail";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-3: 0.187rem;
$spacing-54_5: 3.406rem;
$column-detail-lptop-background: #FF61A0;
$column-detail-lptop-border: #E6337B;
$line-height-1_2: 1.2;

.column-detail {
	&__container {
		background-color: $background-primary;
		box-sizing: border-box;
	}

	&__sugotoku-button {
		margin-top: $spacing-20;
	}

	&__footer {
		margin-top: $spacing-20;
	}

	&__back-button {
		width: 53.3334%;	// 200px
		margin: 0 auto;
		padding: $spacing-16 $spacing-20;
		box-sizing: border-box;
		border: solid 1px $primary;
		font-size: $font-size-14;
		text-align: center;
		line-height: $line-height-1;

		&.is-column-top {
			color: $primary;
		}

		&.is-top {
			background: $primary;
			color: $text-seconday;
		}

		&--wrapper {
			padding-top: $spacing-16;
			box-sizing: border-box;
		}
	}

	/* 特集に戻るボタン */
	&__lptop {
		display: block;
		width: 80%;
		margin: $spacing-20 auto $spacing-8;
		padding: $spacing-10 0;
		font-family: $lp-family-secondary;
		font-size: $font-size-18;
		color: $text-seconday;
		text-align: center;
		line-height: $line-height-1_2;
		background-color: $column-detail-lptop-background;
		border-radius: $spacing-6;
		box-shadow: 0 $spacing-3 $column-detail-lptop-border;
		box-sizing: border-box;
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		padding: 0 $spacing-14;
		box-sizing: border-box;
		bottom: 0;
		opacity: 0;
		transition: opacity .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			max-width: 84px;
			margin-left: auto;
			pointer-events: auto;

			& > img {
				display: block;
				width: 100%;
			}
		}
	}
}

// アニメーション
.fade-enter-active, .fade-leave-active { transition: opacity .2s; }
.fade-enter, .fade-leave-to { opacity: 0; }

</style>
